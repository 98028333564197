.ad-mang-order-request-table {
  min-width: 878px;
}

.place-order-table {
  min-width: 600px;
}
.ad-order-mang-by-order-table {
  min-width: 1267px;
}
.ad-order-mang-place-order-table {
  min-width: 908px;
}
.ad-order-mang-request-by-order-table {
  min-width: 452px;
}

.vertical-scroll-om {
  max-height: 50vh;
}

@media (max-width: 992px) {
  .ad-itemsorders-toggle-con {
    width: 100%;
  }
  .ad-itemsorders-toggle-right-con {
    width: 100%;
    margin-top: 20px;
  }
  .ad-status-search-con {
    width: 220px;
  }
}

@media (max-width: 720px) {
  .ad-status-search-con {
    width: 170px;
  }
}
@media (max-width: 678px) {
  .sm-responsiveness {
    flex-direction: column-reverse;
  }
  .fm-search-input {
    width: 100%;
  }
  .ad-status-search-con {
    width: 100%;
    margin-top: 1rem;
  }
  .fm-table-search-con {
    width: 100% !important;
  }
  .sm-responsiveness {
    .fm-search-input {
      .fm-table-search-con {
        .table-main-search-input {
          width: 93%;
        }
      }
    }
  }
}
