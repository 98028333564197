.left-img-container {
  background-image: url("../../../images/get-started/get-started.png") !important;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-wrap: wrap;
}

.wrap-login100 {
  width: 50%;
  min-height: 100vh;
  background: #fff;
  border-radius: 2px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/*------------------------------------------------------------------
[ Login100 more ]*/
.login100-more {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 50%;
  position: relative;
  z-index: 1;
}

.left-img {
  width: 95%;
  height: 65px;
}

.left-img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.left-img-inner-container > h5 {
  padding-top: 20px;
  padding-bottom: 15px;
  line-height: 44px;
  font-weight: 300 !important;
  text-align: left;
  padding-left: 0.5rem;

  font-size: 40px;
  letter-spacing: 0px !important;
  color: #ffffff;
  opacity: 1;
}
.left-img-inner-container > p {
  padding-left: 0.5rem;
  color: #ffffff;
  text-align: left;
  font-weight: 100 !important;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 16px;
  line-height: 20px;
}

.right-side-container > h1 {
  font-weight: 600 !important;
  font-size: 42px;
  line-height: 48px;
  color: #050505;
}

.sign-in-input-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
 
.sign-in-forget-password {
  // text-decoration-line: underline !important;
  // font: normal normal normal 20px/30px Poppins;
  // letter-spacing: 0px;
  // color: #347fff;
  font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    text-decoration-line: underline !important;
    color: #111111 !important;
}
.sign-in-btn > button {
  width: 50%;
  height: 64px;
  background: #0b4d89;
  border-radius: 33px;
  font-size: 20px !important;
}

.label {
  margin-bottom: 0 !important;
  letter-spacing: 0px;
  color: #000000 !important;
  padding-left: 0.5rem !important;
  opacity: 1;
}
.signin-input {
  width: 100%;
  height: 50px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 33px;
  opacity: 0.5;
}

.remember-box {
  padding-left: 0.5rem;
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #5d5d5d !important;
  opacity: 1;
}
.remember-container {
  margin-left: 0.3rem !important;
}

.forget-verify-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.form-classed {
  width: 100%;
}
.change-order {
  flex-flow: column !important;
}
