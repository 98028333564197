.input-search {
  height: 50px;
  border: none !important;
  background: #eeeeee;
  border-radius: 10px !important;
  width: 250px;
  font-size: 16px;
  padding: 0.75px 15px;
}

.vendor-search-input {
  padding: 0.75px 5px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.search-box-container {
  margin-left: 20px;
}

.vendor-table-search-icon {
  font-size: 30px;
  color: #a2a2a2;
  vertical-align: middle;
}

.vendor-table-search-inner-class {
  white-space: nowrap;
  background: #eeeeee;
  border-radius: 10px !important;
  padding: 0px 5px;
}

.header-button {
  text-align: center;
  font-size: 1rem !important;
  background: transparent linear-gradient(0deg, #2c3e50 0%, #2980b9 100%) 0% 0%
    no-repeat padding-box;
  color: #f0f5f8 !important;
  border-radius: 10px !important;
}
.header-title {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
}
.vendor-icon-container {
  width: 60px;
}

.vendor-data-container {
  box-shadow: 0px 12px 18px #0000000d;
  border-radius: 0px 0px 4px 4px;
}

.modal-label {
  display: flex;
  margin-bottom: 0 !important;
  justify-content: flex-end;
  font: normal normal 600 14px/21px Poppins !important;
  color: #000000 !important;
}
.modal-input {
  // width: 250px;
  display: flex;
  font: normal normal medium 600 14px/21px Poppins;
  opacity: 1;
}
.input-text-area {
  margin-left: 10px;
}

.header-container {
  margin-bottom: 20px;
}

.header-container > h5 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.header-container > button {
  border: none;
}

.vendor-modal-del-btn {
  margin-left: 10px;
  width: 200px;
}
.vendor-modal-cancel-btn {
  background-color: white !important;
  color: #296f9e !important;
  border-color: #296f9e !important;
}

.form-handler {
  margin-right: 20px;
}

.pagination-contain ul li button {
  padding: 0.5em !important;
}
.button-width-vendor {
  width: 75% !important;
  display: block !important;
  margin-right: auto;
  margin-left: auto;
}

.vendor-add-edit-card-container {
  margin: 0 10% 3% 10%;
}

.vendor-add-edit-card-container {
  .validation-handler {
    margin-bottom: 0 !important;
  }
}
.vm-topheader-right {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .input-search {
    width: 200px;
  }
}
