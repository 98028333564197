.fm-navtabs-main {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 18px #0000000d;
  margin-top: 1.2em;
  padding: 13px 0px;
}
.fm-navtabs-main {
  padding-bottom: 0px;
}
.fm-nav-tabs-border > .nav-tabs .nav-item .nav-link,
.fm-nav-tabs-border > .nav-tabs .nav-link.active {
  font-size: 16px;
}
.fm-nav-tabs-border > .nav-tabs > .nav-item .nav-link:hover,
.fm-nav-tabs-border > .nav-tabs > .nav-item .nav-link:focus,
.fm-nav-tabs-border > .nav-tabs > .nav-item .nav-link.active,
.fm-nav-tabs-border > .nav-tabs > .nav-item .nav-link.active:focus-visible,
.fm-nav-tabs-border > .nav-tabs > .nav-item:hover,
.fm-nav-tabs-border > .nav-tabs > .nav-item:focus,
.fm-nav-tabs-border > .nav-tabs > .nav-item:active,
.fm-nav-tabs-border > .nav-tabs > .nav-item:focus-visible {
  border: none;
  isolation: inherit;
  outline: none;
  outline-color: #ffffff !important;
}
.fm-nav-tabs-border > .nav-tabs .nav-item .nav-link {
  color: #c4c4c4;
  padding-bottom: 16px;
  border: none !important;
  outline: none !important;
}
.fm-nav-tabs-border > .nav-tabs .nav-link.active {
  border: none;
  position: relative;
  color: #1374d5;
  outline: none;
  outline-color: #ffffff !important;
  border-color: #ffffff !important;
  padding-bottom: 14px;
  border-bottom: 4px solid #297eb6 !important;
}
// .fm-nav-tabs-border  > .nav-tabs .nav-link.active::after,
// .fm-nav-tabs-border  > .nav-tabs .nav-link:hover.active::after{
//   content: "";
//   height: 4px;
//   width: 100%;
//   position: absolute;
//   left: 0%;
//   top: 128%;
//   background: #297EB6;
// }
