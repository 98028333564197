.finance-order-management-order-by-order-modal-table {
  min-width: 1223px;
}
.finance-place-order-modal-table {
  min-width: 1100px;
}
.finance-order-mang-scrap-return-table {
  min-width: 966px;
}
.finance-order-mang-scrap-return-department-table {
  min-width: 850px;
}
.finance-order-management-order-by-item-table {
  min-width: 1008px;
}
.finance-order-management-place-order-modal-table {
  min-width: 1464px;
}
.finance-om-byorder-table {
  min-width: 450px;
}
.finance-om-orderplaced-table {
  min-width: 610px;
}
.finance-om-pullback-table {
  min-width: 585px;
}
.finance-om-pullback-detail-table {
  min-width: 780px;
}
.finance-vertical-scroll-om {
  max-height: 50vh;
}
.po-data-table-finance table tbody tr:last-child td {
  padding-bottom: 0.75em !important;
}

@media (max-width: 992px) {
  .warehouse-nav-tabs > .nav-tabs .nav-item .nav-link {
    width: 135px;
    height: 35px;
    font-size: 14px;
  }

  .fm-table-selectall {
    width: 0%;
  }
  .byorder-toggle {
    width: 100%;
  }
  .byorder-toggle-con {
    margin-top: 1rem;
    width: 100%;
  }
  .finance-form-check {
    width: 0% !important;
  }
  .finance-om-scrap-return,
  .finance-location-search {
    width: 100%;
  }
  .finance-om-scrap-return > div {
    width: 50%;
  }
  .finance-location-search {
    margin-top: 0.5rem;
  }
  .finance-om-sr-header {
    .finance-location-search > div > div > input {
      width: 94% !important;
    }
  }
}

@media (max-width: 720px) {
  .fm-itemsorders-toggle-con {
    width: 100%;
  }
  .fm-itemsorders-toggle-right-con {
    width: 100%;
    margin-top: 1rem;
  }
  .finance-orderbyitems-autocomplete {
    justify-content: flex-start;
  }
  .finance-byitems-search-ip,
  .finance-byorders-search-ip,
  .finance-byitems-search-ip > div > .fm-table-search-con,
  .finance-byorders-search-ip > div > .fm-table-search-con,
  .finance-orderbyorders-autocomplete > .status-search-con {
    width: 100% !important;
  }
  .finance-byitems-search-ip > div > .fm-table-search-con > input,
  .finance-byorders-search-ip > div > .fm-table-search-con > input {
    width: 93%;
  }
  .finance-byorders-search-ip > div > .fm-table-search-con {
    margin-top: 0.5rem;
  }
  .finance-orderbyorders-autocomplete,
  .finance-om-scrap-return {
    flex-direction: column;
    align-items: flex-start;
  }
  .finance-om-scrap-return > div {
    width: 100%;
  }
  .finance-om-scrap-return > .autocomplete-first {
    margin-right: 0px !important;
    margin-bottom: 0.5rem;
  }
  .pullback-detail-btn-con > button {
    height: 35px;
    width: 180px !important;
  }
}
