.gt-modal {
    .modal-content {
        @include radius(8px);
        @include shadow(0px 0px 20px $shadow-clr);
        border-width: 0;
    }
   
    &-size-1 {
        max-width: em(455);
    }
    &-size-2 {
        max-width: em(584);
    }
    &-size-3 {
        max-width: em(1297);
        .modal-body {
            padding: em(20) em(120) em(100);
        }
    }
    &-size-4 {
        // max-width: em(1620);
        max-width: 95%;
    }
    &-size-5 {
        max-width: em(995);
        // max-width: 95%;
    }
    &-size-6 {
        max-width: em(777);
        .modal-body {
            padding: em(20) em(100) em(100) em(100);
        }
    }
    & .close-modal{
        transform: scaleY(0.8);
        -webkit-transform: scaleY(0.8);
        font-family: cursive;
        font-weight: 600;
    }
}


@media (max-width: 700px) {
    .gt-modal{
        &-size-5 {
            max-width: em(795);
        }
    }
}

@media (max-width: 576px) {
    .gt-modal{
        &-size-3 {
            .modal-body {
                padding: em(20) em(20) em(100);
            }
        }
    }
}