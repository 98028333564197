.splash-container {
  height: 100vh;
}
.splash-img-container {
  background-image: url("../../assets/images/get-started/get-started.png") !important;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
}

.splash-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.logo {
  width: 95%;
  height: 55px;
}
.logo > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.data {
  margin-top: 60px;
}
.data > h7 {
  font-size: 30px;
  line-height: 54px;
  color: #ffffff;
  text-align: center;
}
.data > p {
  line-height: 25px;
  color: #ffffff;
  text-align: center;
}
.get-started-btn > button {
  width: 200px;
  // width: 100%;
  // max-width: 30%;
  height: 64px;
  background: #f7f4f4;
  border-radius: 30px;
  border: none;
}
@media (max-width: 2200px) {
  .data > p {
    padding-left: 20%;
    padding-right: 20%;
  }
  .get-started-btn > button {
    width: 30%;
    height: 50px;
  }
}
@media (max-width: 1199px) {
  .get-started-btn > button {
    font-size: 15px !important;
    width: 30%;
    height: 50px;
  }
}
@media (max-width: 991px) {
  .data > p {
    padding-left: 2%;
    padding-right: 2%;
  }
  .get-started-btn > button {
    font-size: 15px !important;
    width: 30%;
    height: 50px;
  }
}
@media (max-width: 575px) {
  .logo > img {
    padding-left: 80px;
    padding-right: 80px;
  }
  //   .get-started-btn > button {
  //     font-size: 15px !important;
  //     width: 30%;
  //     height: 50px;
  //   }
  .data {
    margin-top: 30px;
  }
  .data > p {
    font-size: 12px !important;
  }
}
