
.wh-inventory-management > .nav-tabs .nav-link.active, 
.wh-inventory-management > .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs  > .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs  > .nav-tabs .nav-link.active
{
  border-radius: 4px;
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  border: none;
  justify-content: center;
  // text-transform: capitalize;
}
.wh-inventory-management > .nav-tabs > .nav-item .nav-link {
  background: #BFBFBF;  
}
.wh-inventory-management > .nav-tabs .nav-link.active, 
.wh-inventory-management > .nav-tabs .nav-item.show .nav-link {
  background: #0B4D89;
}
.wh-inventory-management > .nav-tabs > .nav-item .nav-link:hover,
.wh-inventory-management > .nav-tabs > .nav-item .nav-link:focus {
  border: none;
  isolation: inherit;
}
.warehouse-nav-tabs  > .nav-tabs .nav-item .nav-link,
.warehouse-nav-tabs  > .nav-tabs .nav-link.active{
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 100;
  
}
.warehouse-nav-tabs  > .nav-tabs .nav-item .nav-link {
  background: #CED4DA;
  opacity: 1;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  color: #4E4E4E;
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  // width: 180px;  
  width: 200px;
  height: 60px;
  padding: 0px;
}
.warehouse-nav-tabs  > .nav-tabs .nav-link.active{
  color: #1374D5;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  margin-right: 2px;
  margin-left: 2px;
  font-weight: 600;
}
.warehouse-nav-tabs  > .nav-tabs .nav-link.active{
  margin-right: 0px;
  margin-left: 0px;
}
.warehouse-nav-tabs  > .nav-tabs .nav-link:first-child
{
  margin-right: 2px;
}
.warehouse-nav-tabs  > .nav-tabs .nav-link.active:last-child {
  margin-right: 2px;
}


// .warehouse-nav-tabs  > .nav-tabs .nav-link.active::after,
// .warehouse-nav-tabs  > .nav-tabs .nav-link:hover.active::after{
//   content: "";
//   height: 4px;
//   width: 80%;
//   position: absolute;
//   left: 10%;
//   top: 100%;
//   background: #0B4D89;
// }

.wh-im-table-heading{
  font-size: 18px;
}

.wh-im-wh-gi-con > .gt-data-table table thead th,
.wh-im-wh-gi-con > .gt-data-table table tbody td,
.wh-im-wh-ch-con > .gt-data-table table tbody td, 
 .wh-im-wh-ch-con > .gt-data-table table thead th{
  text-align: center;
}
.card-body{
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
}
.bg-green-light {
  background: rgba(52, 195, 143, 0.5);
}
.bg-red-light {
  background: rgba(244, 106, 106, 0.5);
}
.bg-orange-light {
  background: rgba(241, 180, 76, 0.5);
}
.wh-im-global-quantity{
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 20px;
  width: 110px;
  margin: auto;
}
.wh-im-wi-ch-btn{
  border: 1px solid #CED4DA !important;
  border-radius: 5px !important;
  padding: 1px 5px !important;
}
.wh-im-dpgi-status{
  width: 200px;
}

.icon-img-con{
  width: 43px;
  height: 43px;
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: inline-block;
}

.inventory-req-icon {
  padding: 10px;
}

.img-general  {
  padding: 7px;
  width: 100%;
  height: 100%;
}

.table-outer-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #495057;
  display: inline-block;
}

.admin-location > .al-navtabs-main {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 18px #0000000d;
  margin-top: 1.2em;
  padding: 13px 0px;
  padding-bottom: 0px;
}

.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .al-nav-link {  
  font-size: 19px;
  cursor: pointer;
  font-weight: 500;
}
.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .bg-navitems-secondary{
  padding-bottom: 16px;
  cursor: pointer;
}
.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .bg-navitems-primary{
  color: #1374D5;
  padding-bottom: 14px;
  border-bottom: 4px solid #297EB6;
  cursor: pointer;
}
.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .bg-navitems-primary > a{
  color: #1374D5;  
}
.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .bg-navitems-primary > a,
.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .bg-navitems-primary > a:hover,
.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .bg-navitems-primary > a:focus{
  isolation: inherit;
  border: none;
}
.admin-location > .al-navtabs-main > .al-nav-tabs > .al-nav-item .bg-navitems-secondary > a
{ 
  color: #c4c4c4;
}


  .status-header-search-admin__container {
    width: 70%;
  }

  .status-header-search-admin__indicator {
    color: #74788D !important;
    padding-left: 0px !important;
    padding-right: 2px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .status-header-search-admin__indicator{
    color: black !important;
  }
  .status-header-search-admin__placeholder{
    color: black !important;
    font-weight: 500;
  }
  .status-header-search-admin__single-value{
    font-weight: 500;
  }
  .status-header-search-admin__indicators {
    min-width: inherit;
  }

  .status-header-search-admin__indicator-separator {
    display: none;
  }
  .inventory-description{
    background: #F5F5F5;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #CDCDCD;
   height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .admin-im-detail-table > tbody > tr > td {
    color: #000 !important;
  }
  
/* TABLE INNER BUTTONS RIGHT SIDE OF HEADING */
@media (max-width: 1400px) {
  
  .wh-im-search-input{
    padding: 4px 5px !important;
  }
}
@media (max-width: 992px) {
  
  // .wh-im-search-label{
  //   display: none;
  // }

 .wh-im-wh-ch-con > .gt-data-table table tbody tr td:first-child, 
 .wh-im-wh-ch-con > .gt-data-table table thead tr th:first-child{
  padding-left: 0px !important;
}
 
}
@media (max-width: 768px) {
  .wh-inventory-management > .nav-tabs .nav-link.active, 
  .wh-inventory-management > .nav-tabs .nav-item .nav-link{
    padding: 5px 10px;
  }
  .wh-im-header-inner-btns {
    // width: 100%;
    // margin: 5px 0px !important;
    min-width: inherit !important;
  }
  // .wh-im-header-inner-btns-con {
  //   width: 100%;
  // }
}
