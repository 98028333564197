.ad-general-inventory-table {
  min-width: 799px;
}
.ad-tool-inventory-table {
  min-width: 914px;
}
.ad-warehouse-general-inventory-table {
  min-width: 546px;
}
.ad-warehouse-chemicals-table {
  min-width: 376px;
}
.vertical-scroll-im {
  max-height: 50vh;
}
@media (max-width: 992px) {
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-admin-ic1,
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-admin-ic2,
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-admin-ic3 {
    width: 135px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
  .gi-top-heading {
    font-size: 20px;
  }
  .status-header-search-admin__control {
    min-height: 40px !important;
  }
  .ad-location-search-box .vendor-table-search-inner-class > input {
    height: 40px !important;
    width: 190px !important;
  }
}
@media (max-width: 720px) {
  .admin-inventory-management-general-inventory-responsiveness {
    flex-direction: column-reverse;
  }
  .admin-inventory-management-general-inventory-responsiveness {
    .status-search-con {
      margin-top: 0.5rem;
      margin-right: 0rem !important;
    }
  }

  .gi-finance-inner-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ad-location-search-box {
    width: 100%;
  }
  .ad-location-search-box .vendor-table-search-inner-class > input {
    width: 88% !important;
  }
  .wh-im-dpgi-status {
    width: 100% !important;
  }
  .department-gi-top-autocomplete {
    margin-bottom: 0.5rem;
  }
  .finance-item-listing-header {
    .wh-im-dpgi-status {
      width: 100% !important;
      height: inherit;
      padding-top: 6.5px;
      padding-bottom: 6.5px;
    }
  }
}
@media (max-width: 650px) {
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-admin-ic2,
  .warehouse-nav-tabs > div > .nav-tabs .nav-item .nav-link-admin-ic3 {
    width: 110px !important;
    height: 35px !important;
  }
}
