.header-title {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0em !important;
}

.header-button {
  width: 250px;
  text-align: center;
  font-size: 1rem;
  background: transparent linear-gradient(0deg, #2c3e50 0%, #2980b9 100%) 0% 0%
    no-repeat padding-box;
  color: #f0f5f8 !important;
  border-radius: 10px !important;
}

// .location-search-box {
//   margin-left: 20px;
// }

.vendor-search-input {
  padding: 0.75px 5px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.input-search {
  height: 50px;
  border: none !important;
  background: #eeeeee;
  border-radius: 10px !important;
  width: 250px;
  font-size: 16px;
  padding: 0.75px 15px;
}

.vendor-table-search-icon {
  font-size: 30px;
  color: #a2a2a2;
  vertical-align: middle;
}

.vendor-table-search-inner-class {
  white-space: nowrap;
  background: #eeeeee;
  border-radius: 10px !important;
  padding: 0px 5px;
}

@media (max-width: 1300px) {
  .input-search {
    width: 200px;
  }
}
