
.pagination-container {
    // font-family: $font-primary;
    // font-size: $font-medium;
    font-size: em(13);
}
.pagination-container ul li button {
    // border: 1px solid $gray-dark;
    border: none;
    // margin: em(12);
    padding: em(6) em(14);
    border: 1px solid var(--bs-pagination-disabled-color)
}
.page-link {
    // background-color: $gray-light-4;
    background-color: transparent;
    color: $gray-dark;
}
.page-item.active .page-link {
    // background: transparent
    //         linear-gradient(
    //             120deg,
    //             #fec108 0%,
    //             #f67d3d 11%,
    //             #f65d4f 21%,
    //             #ce4387 32%,
    //             #b14ea6 44%,
    //             #7a65d5 57%,
    //             #746bd9 70%,
    //             #4c8fed 82%,
    //             #3bafe5 90%,
    //             #0af3c0 100%
    //         )
    //         0% 0% no-repeat padding-box;
    background-color: $btn-table-bg;
}

.page-item.disabled .page-link {
    // background-color: $black;
    // color: $red !important;
    // border: 1px solid $gray-dark;
}

.page-link:hover {
    background-color: $gray-dark-2;
    color: $white;
    // border: 1px solid $gray-dark;
}
// .page-link:focus {
//     border: none;
// }

